import React, { useContext } from 'react'
import NavContext from 'components/NavProvider'
import useStoryblokApi from 'hooks/useStoryblokApi'
import useRemark from 'hooks/useRemark'
import Component from './index.js'

export default ({ data, ...props }) => {
  const { lang } = useContext(NavContext)
  console.log(lang)
  const product =
    useStoryblokApi({
      by_uuids: data.product
    })?.stories[0] || {}

  return (
    <Component
      data={{
        ...data,
        product: [
          {
            ...product,
            full_slug:
              lang === 'default'
                ? product.full_slug
                : `${lang}/${product.full_slug}`,
            storyblokId: product.id
          },
        ],
      }}
      {...props}
    />
  )
}
